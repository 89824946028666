<template>
  <div class="gift-list">
    <header>
      <m-page-header title="礼品卡列表" />
    </header>
    <main>
      <div class="control-bar">
        <div class="search-wrap">
          <el-input
            placeholder="请输入商品名称或编号"
            v-model="searchQuery"
            class="input-with-select"
            size="small"
          >
            <template #append>
              <el-button size="medium" icon="el-icon-search" @click="search"></el-button>
            </template>
          </el-input>
        </div>
        <div class="btn-wrap">
          <router-link :to="getRoute('gift-list-edit')">
            <el-button size="small" type="primary">新建礼品卡</el-button>
          </router-link>
        </div>
      </div>

      <div class="list-wrap">
        <m-table
          :data="dataList"
          :loading="listLoading"
          :pagination="pagination"
          @currentChange="changePage"
          @selection-change="changeSelection"
          :control="[
            {
              text: '批量删除',
              on: 'batchDel',
              loading: delLoading,
            },
          ]"
          @batchDel="batchDel"
          ref="mTable"
        >
          <el-table-column type="selection"> </el-table-column>

          <el-table-column label="商品">
            <template #default="goods">
              <div class="goods-wrap">
                <m-goods
                  :img="goods.row.image"
                  :href="`//${state.storeDomain}/h-product-detail.html?goods_id=${goods.row.goods_id}`"
                  :detail="[
                    {
                      text: goods.row.base_name,
                      type: 'link,bold',
                      href: `//${state.storeDomain}/h-product-detail.html?goods_id=${goods.row.goods_id}`,
                    },
                    { text: goods.row.category },
                    { text: goods.row.codeno },
                  ]"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="300">
            <template #default="goods">
              <el-tag v-if="goods.row.offer_status == 1">全部正常</el-tag>
              <el-tag type="danger" v-if="goods.row.offer_status == 2">已失效</el-tag>
              <el-tag type="warning" v-if="goods.row.offer_status == 3">部分失效</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="220px">
            <template #default="goods">
              <el-space>
                <router-link :to="getRoute(`gift-list-edit/${goods.row.id}`)">
                  <el-button size="small"> 编辑 </el-button>
                </router-link>
                <el-button
                  size="small"
                  :loading="goods.row.loading"
                  @click="delGift(goods.row.id, goods.$index)"
                >
                  删除
                </el-button>
              </el-space>
            </template>
          </el-table-column>
        </m-table>
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useStore } from 'vuex';

import MPageHeader from '@/components/pageHeader/PageHeader';
import MTable from '@/components/table/Table';
import MGoods from '@/components/goods/Goods';
import { deleteGift, getListData } from '../api/giftList';
import { getRoute } from '../../../tools/config';
export default {
  name: 'GiftList',
  components: {
    MPageHeader,
    MTable,
    MGoods,
  },
  setup() {
    const dataList = ref([]);
    const { state } = useStore();

    const listLoading = ref(false);
    const pagination = ref({});
    const setList = async (page = 1, keyword = '') => {
      const res = await getListData(
        {
          keyword: keyword,
          page_size: 10,
          page,
        },
        listLoading,
      );
      const { status, data } = res;
      if (status == 0) return;
      dataList.value = data.list;
      const pageList = data.page_list;
      pagination.value = {
        total: parseInt(pageList.total),
        pageSize: pageList.page_size,
        currentPage: pageList.page,
      };
    };

    const searchQuery = ref('');
    // 搜索
    const search = () => {
      setList(1, searchQuery.value);
    };

    // 分页
    const changePage = cur => {
      setList(cur);
    };

    const delLoading = ref(false);

    // 选择
    const curSelectArr = ref([]);
    const changeSelection = selects => {
      curSelectArr.value = selects;
    };

    // 删除
    const mTable = ref();
    const delGift = async (ids, indexs) => {
      indexs = Array.isArray(indexs) ? indexs : [indexs];

      if (indexs.length) {
        ElMessageBox.confirm('是否确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            indexs.forEach(item => {
              dataList.value[item].loading = true;
            });

            const res = await deleteGift({ id: ids }, delLoading);

            indexs.forEach(item => {
              dataList.value[item].loading = true;
            });

            if (res.status) {
              indexs.forEach(item => {
                delete dataList.value[item];
              });
              ElMessage.success('删除成功');

              mTable.value.closeControlBar();

              // 记录是否被删除完，删除之后 dataList的length需要手动清零
              let isEmpty = true;
              dataList.value.forEach(item => {
                if (item) isEmpty = false;
              });

              if (isEmpty) {
                dataList.value = [];
              }
            }
          })
          .catch(() => {});
      }
    };

    const batchDel = () => {
      // 获取选中的选项的索引和id
      const selectIndexs = [];
      let ids = '';
      curSelectArr.value.forEach(select => {
        const selectId = select.id;
        ids += selectId + ',';
        dataList.value.forEach((item, index) => {
          if (item.id === selectId) {
            selectIndexs.push(index);
          }
        });
      });

      ids = ids.substr(0, ids.length - 1);
      delGift(ids, selectIndexs);
    };

    return {
      dataList,
      setList,
      searchQuery,
      listLoading,
      pagination,
      changePage,
      delGift,
      delLoading,
      getRoute,
      batchDel,
      changeSelection,
      search,
      state,
      mTable,
    };
  },
  mounted() {
    this.setList();
  },
};
</script>

<style lang="scss" scoped>
main {
  padding: 0 20px;
  .control-bar {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
  }
  .list-wrap {
    .el-button {
      min-width: 80px;
    }
  }
  .goods-wrap {
    max-width: 500px;
  }
}
</style>
