import request from '@/tools/request';

export const getListData = (params, loading) => {
  return request({
    url: '/giftcard-config-list.html',
    params,
    loading,
  });
};

export const deleteGift = (data, loading) => {
  return request({
    url: '/giftcard-config-delete.html',
    method: 'post',
    data,
    loading,
  });
};
